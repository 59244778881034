import React, { Component } from "react"
import css from "./stylesheets/AnswerBox.module.scss"

class AnswerBox extends Component {
  render() {
    return (
      <div className={css.answerContent}>
        <div className={css.answerPrefix}>A.</div>
        <div>{this.props.children}</div>
      </div>
    )
  }
}

export default AnswerBox
