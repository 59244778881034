import React from "react"
import BasicText from "../../components/BasicText"
import LayoutBasic from "../../components/LayoutBasic"
import AnswerBox from "../../components/AnswerBox"
import Seo from "../../components/Seo"
import QuestionAndAnswerToggle from "../../components/QuestionAndAnswerToggle"
import CenterContentLayout from "../../components/CenterContentLayout"
import css from "./stylesheets/faq.module.scss"

export default props => (
  <div>
    <Seo title={`FAQ`} />
    <LayoutBasic narrow={true} lang={`ja`}>
      <BasicText>
        <CenterContentLayout narrow={true}>
          <h1>FAQ</h1>
          <p>
            よくある質問です。みなさんの生活の役に立つ団体や、サービスなども紹介しています。
          </p>

          <div>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`困ったら誰に相談すればいいですか？`}
            >
              <AnswerBox>
                <p>
                  まずは、一緒に働いている人や、監理団体・登録支援団体に相談してください。それでも解決しなかった場合は、さまざまな無料の相談窓口があります。
                  <br />
                  『法テラス』では、通訳と弁護士と3人で話すことができます。仕事、事故、借金から、災害に関することまで、相談内容にあわせて、日本の法律や専門家を無料で紹介してくれます。弁護士費用を立て替えてくれるサービスもあります。
                  <br />
                  『ASIAN PEOPLE’S FRIENDSHIP
                  SOCIETY』では、トラブルだけでなく、生活についても相談ができます。
                  <br />
                  『東京労働局』では、誰でも電話で仕事についての相談ができます。
                  <br />
                  『法務局』では、誰でも電話・メールなどで人権についての相談ができます。
                  <br />
                  外国人介護者は、『国際厚生事業団』に電話・メール・Facebook・LINEで相談ができます。
                  <br />
                  技能実習生は、『外国人技能実習機構』に電話・メールで相談ができます。
                  <br />
                  また、住んでいる地域によっては、都道府県や市区町村の「役所」や、「国際交流協会」に相談窓口がある場合があります。
                </p>
                <div class={css.links}>
                  <p>
                    国が設立した弁護士への相談窓口｜
                    <a
                      href={`https://www.houterasu.or.jp/multilingual/index.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      法テラス
                    </a>
                    <br />
                    <small>
                      （やさしい日本語／日本語／英語／中国語／韓国・朝鮮語／フィリピノ語／ベトナム語／タイ語／ネパール語／スペイン語／ポルトガル語）
                    </small>
                  </p>
                  <p>
                    非営利団体（Nonprofit Organization）による相談窓口｜
                    <a
                      href={`http://apfs.jp/talkwithapfs`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ASIAN PEOPLE’S FRIENDSHIP SOCIETY
                    </a>
                    <br />
                    <small>
                      （英語／中国語／韓国・朝鮮語／フィリピノ語／ビルマ語／ベンガル語／ウルドゥー語／ペルシャ語／フランス語）
                    </small>
                  </p>

                  <p>
                    国の外国人向けの労働相談窓口｜
                    <a
                      href={`https://jsite.mhlw.go.jp/tokyo-roudoukyoku/news_topics/kyoku_oshirase/roudou_kijun/20050415-foreigner.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      東京労働局
                    </a>
                    <br />
                    <small>
                      （日本語／英語／中国語／フィリピノ語／ベトナム語／ビルマ語／ベンガル語／ネパール語／スペイン語／ポルトガル語）
                    </small>
                  </p>
                  <p>
                    国の外国人のための人権相談窓口｜
                    <a
                      href={`http://www.moj.go.jp/JINKEN/jinken21.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      法務省
                    </a>
                    <br />
                    <small>
                      （日本語／英語／中国語／韓国・朝鮮語／フィリピノ語／ベトナム語／ポルトガル語）
                    </small>
                  </p>
                  <p>
                    国が認めた外国人介護者向けの相談窓口｜
                    <a
                      href={`https://jicwels.or.jp/?page_id=11193`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      国際厚生事業団
                    </a>
                    <br />
                    <small>
                      （日本語／英語／中国語／フィリピノ語／ベトナム語／インドネシア語／ビルマ語）
                    </small>
                  </p>
                  <p>
                    国が認めた技能実習生の相談窓口｜
                    <a
                      href={`https://www.otit.go.jp`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      外国人技能実習機構
                    </a>
                    <br />
                    <small>
                      （英語／中国語／フィリピノ語／ベトナム語／カンボジア語／タイ語／インドネシア語／ビルマ語）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`病気になったらどうしたらいいですか？`}
            >
              <AnswerBox>
                <p>
                  日本では、母国語を使える病院が少ないので、できるだけ日本語を話せる人と一緒に病院へ行ってください。もし誰もいなかった場合は、『AMDA国際医療情報センター』では、母国語が使える病院を探したり、通訳をしてくれる電話のサービスがあります。
                  <br />
                  病院へは「健康保険証」を持っていってください。「在留カード」や「パスポート」など身分証明書も必要です。アレルギーや飲んでいる薬、宗教上の理由で生活や治療に制限がある場合は、事前に看護師に伝えましょう。
                </p>
                <div class={css.links}>
                  <p>
                    非営利団体（Nonprofit Organization）による医療相談窓口｜
                    <a
                      href={`https://www.amdamedicalcenter.com`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      AMDA国際医療情報センター
                    </a>
                    <br />
                    <small>
                      （やさしい日本語／英語／中国語／韓国・朝鮮語／フィリピノ語／ベトナム語／タイ語／スペイン語／ポルトガル語）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`日本語がうまくなるにはどうしたらいいですか？`}
            >
              <AnswerBox>
                <p>
                  日本語がうまくなるには、書く、読む、聞く、話すことを通して、毎日くり返し使うことが大事です。ぜひ、積極的に書いたり、話したりしてみてください。
                  <br />
                  日本語だけでなく、介護や日本文化についても同時に学ぶことができる『日本語でケアナビ』『にほんごをまなぼう』『MARUGOTO』などのウェブサイトがあります。
                </p>
                <div class={css.links}>
                  <p>
                    国が認めた団体による、介護の仕事で使う日本語を学ぶ
                    <a
                      href={`https://eng.nihongodecarenavi.jp`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      『日本語でケアナビ』
                    </a>
                    ｜国際交流基金
                    <br />
                    <small>（日本語／英語／インドネシア語）</small>
                  </p>
                  <p>
                    国が認めた団体による、介護の仕事で使う日本語を学ぶ
                    <a
                      href={`https://aft.kaigo-nihongo.jp/rpv`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      『にほんごをまなぼう』
                    </a>
                    ｜日本介護福祉士会
                    <br />
                    <small>（日本語／英語）</small>
                  </p>
                  <p>
                    国が認めた団体による、日本語と日本文化を学ぶ
                    <a
                      href={`https://marugotoweb.jp/ja/index.php`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      『MARUGOTO』
                    </a>
                    ｜国際交流基金
                    <br />
                    <small>（日本語／英語／スペイン語）</small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`日本の生活で気をつけることはありますか？`}
            >
              <AnswerBox>
                <p>
                  ゴミをわけて捨てたり、使い終わったトイレットペーパーをトイレに流すなど、みなさんの母国と違う生活のルールがいくつかあります。
                  <br />
                  日本の生活情報については、『多言語生活情報』『外国人生活支援ポータルサイト』『Handbook
                  for Learning Japanese and Life in
                  Japan』などのウェブサイトがあります。
                </p>
                <div class={css.links}>
                  <p>
                    財団による、外国人のための
                    <a
                      href={`http://www.clair.or.jp/tagengo`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      「多言語生活情報」
                    </a>
                    ｜自治体国際化協会
                    <br />
                    <small>
                      （やさしい日本語／日本語／英語／中国語／韓国・朝鮮語／フィリピノ語／ベトナム語／タイ語／インドネシア語／ビルマ語／ロシア語／ドイツ語／フランス語／スペイン語／ポルトガル語）
                    </small>
                  </p>
                  <p>
                    国による
                    <a
                      href={`http://www.moj.go.jp/nyuukokukanri/kouhou/nyuukokukanri10_00055.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      「外国人生活支援ポータルサイト」
                    </a>
                    ｜法務省
                    <br />
                    <small>（やさしい日本語／日本語／英語／ベトナム語）</small>
                  </p>
                  <p>
                    国による
                    <a
                      href={`https://www.bunka.go.jp/english/policy/japanese_language/education/handbook`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      「Handbook for Learning Japanese and Life in Japan」
                    </a>
                    ｜文化庁
                    <br />
                    <small>
                      （日本語／英語／中国語／韓国・朝鮮語／スペイン語／ポルトガル語）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`毎日のお祈りはできますか？`}
            >
              <AnswerBox>
                <p>
                  仕事中の礼拝や、ヒジャブ（Hijab）を被ってもいいかなどは、施設によります。ただ、日本の介護施設には、それぞれの宗教や文化を尊重することが求められています。
                  <br />
                  宗教や文化の違いに理解のある施設も増えてきているので、募集や面接のときに、条件や環境をしっかり確認することをおすすめします。
                </p>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`食事があわなかったら、どうしたらいいですか？`}
            >
              <AnswerBox>
                <p>
                  日本では和食だけでなく、いろいろな国や地域の料理を食べることができます。母国の食材が買えるスーパーや、オンラインショップを使って、自分で料理をつくる人も多いです。
                  <br />
                  スーパーやコンビニエンスストア、レストランでもさまざまな選択肢があるので、自分にあった食事を見つけられると思います。
                </p>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`家族にはいくら仕送りができますか？`}
            >
              <AnswerBox>
                <p>
                  給与は施設ごとに違いますが、介護職員の1か月の平均給与は181,220円という調査結果が出ています
                  <sup>※</sup>
                  。そこから「社会保険料」「所得税」「住民税」が引かれた金額のなかから、家賃、電気・ガス・水道などの光熱費、Wi-Fi代、食費などの生活費を支払うことになります。
                  <br />
                  このウェブサイトに出てくる先輩たちに聞くと、家族への仕送りは3〜6万円くらいが多いようです。みなさんの生活と無理のないバランスを見つけてください。
                </p>
                <ul className={`notes`}>
                  <li>
                    <i>※</i>
                    <span>
                      厚生労働省「
                      <a
                        href={`https://www.mhlw.go.jp/toukei/saikin/hw/kaigo/jyujisya/19/dl/30gaiyou.pdf`}
                        rel={`noopener noreferrer`}
                        target={`_blank`}
                      >
                        介護従事者処遇状況等調査結果の概要
                      </a>
                      」（2018年）より。
                    </span>
                  </li>
                </ul>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`地震や津波、台風が来たらどうしたらいいですか？`}
            >
              <AnswerBox>
                <p>
                  日本は、自然災害の多い国のひとつです。地震・津波、台風、大雨・洪水、大雪、土砂災害など、さまざまな危険があります。
                  <br />
                  こうした災害情報は『気象庁』が発表するので、インターネット・テレビ・ラジオからチェックしてください。また、災害情報を教えてくれるアプリもたくさんあります。
                  <br />
                  普段から防災対策をして、いざというときには落ち着いて行動できるようにしましょう。
                </p>
                <div class={css.links}>
                  <p>
                    国による
                    <a
                      href={`https://www.jma.go.jp/jma/kokusai/multi.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      「Multilingual Information on Disaster Mitigation」
                    </a>
                    ｜気象庁
                    <br />
                    <small>
                      （日本語／英語／中国語／韓国・朝鮮語／フィリピノ語／ベトナム語／タイ語／インドネシア語／ネパール語／スペイン語／ポルトガル語）
                    </small>
                  </p>
                  <p>
                    国が監修した災害情報アプリ
                    <a
                      href={`https://www.rcsc.co.jp/safety`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      「Safety tips」
                    </a>
                    ｜アールシーソリューション
                    <br />
                    <small>
                      （日本語／英語／中国語／韓国・朝鮮語／フィリピノ語／ベトナム語／タイ語／インドネシア語／ネパール語／スペイン語／ポルトガル語）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
          </div>
        </CenterContentLayout>
      </BasicText>
    </LayoutBasic>
  </div>
)
