import React, { Component } from "react"
import IconArw from "../images/icon-accordion-arw.svg"
import css from "./stylesheets/QuestionAndAnswerToggle.module.scss"

class QuestionAndAnswerToggle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isHover: false,
    }
    this.doToggle = this.doToggle.bind(this)
  }

  doToggle() {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }))
  }

  render() {
    let questionIconArwState = ``
    let answerBodyIsOpen = ``
    if (this.state.isOpen) {
      questionIconArwState = css.questionIconArwIsOpen
      answerBodyIsOpen = css.answerBodyIsOpen
    }
    let questionIsFullWidth = ``
    if (this.props.fullWidth) {
      questionIsFullWidth = css.questionIsFullWidth
    }
    // やさしい日本語の場合、ルビの高さ分「Q.」の位置調整
    let questionIsTall = ``
    if (this.props.isTall) {
      questionIsTall = css.questionIsTall
    }

    return (
      <div className={css.base}>
        <div className={css.question + ` ` + questionIsFullWidth}>
          <button
            className={`resetButton ` + css.buttonAction}
            onClick={this.doToggle}
          >
            <h3
              className={questionIsTall}
              dangerouslySetInnerHTML={{
                __html: `<i>Q.</i>` + this.props.question,
              }}
            />
          </button>
          <div className={css.questionIconArw + ` ` + questionIconArwState}>
            <img src={IconArw} alt="" />
          </div>
        </div>
        <div className={css.answerBody + ` ` + answerBodyIsOpen}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default QuestionAndAnswerToggle
